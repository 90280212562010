import { Business } from 'models/entities/business';

class Condition {

  readonly closed: boolean[];
  readonly deleted: boolean;

  constructor() {
    this.closed = [true, false];
    this.deleted = false;
  }

  filter(all: Business[]): Business[] {
    return all.filter(it => this.check(it));
  }

  private check(business: Business): boolean {
    if (!this.closed.includes(business.closed)) return false;
    else if (business.deleted !== this.deleted) return false;
    return true;
  }

}

export { Condition };