import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import { Business } from 'models/entities/business';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useMemory, BusinessScope } from 'views/services/memory';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { Seo } from 'views/components/seo';
import { Root, Frame, Businesses, Info } from './index.styled';

const Home = () => {

  const auth = useAuth();
  const memory = useMemory();
  const [model, setModel] = useState(new Model());

  function goTo(business: Business) {
    if (!model.collection) throw new Error('model.collection is undefined');
    memory.set('business-scope', new BusinessScope({ business, businesses: model.collection }));
    navigate(`/${business.code}`);
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} border={true} />
      {!model.collection && <InterfaceLoading header={false} />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData header={false}>No Business</NoInterfaceData> : (
            <Businesses>
              <ol>
                {model.list.map((it, i) => (
                  <li key={i} onClick={() => goTo(it)}>
                    <Info>
                      <hgroup>
                        <h1>{it.name}</h1>
                        <p>{it.location}</p>
                      </hgroup>
                    </Info>
                  </li>
                ))}
              </ol>
            </Businesses>
          )}
        </Frame>
      )}
    </Root>
  );

};

export default Home;
export const Head = () => (<Seo title="Home" />);