import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightTextColor, lineColor, primaryColor, vividPrimaryColor, primaryTextColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export const Root = styled.div`
`;

export const Frame = styled.div`
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  overflow: scroll;
`;

export const Businesses = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid ${lineColor};
    pointer-events: none;
  }
  & > ol {
    width: 100vw;
    background-color: ${baseColor};
    & > li {
      position: relative;
      &::after {
        content: '';
        z-index: 0;
        position: absolute;
        top: -0.5px;
        left: 0;
        right: 0;
        bottom: -0.5px;
        border-top: 1px solid ${lineColor};
        border-bottom: 1px solid ${lineColor};
      }
      &:hover {
        cursor: pointer;
        background-color: ${primaryColor};
        &::after {
          z-index: 1;
          border-top: 1px solid ${vividPrimaryColor};
          border-bottom: 1px solid ${vividPrimaryColor};
        }
      }
    }
  }
`;

export const Info = styled.div`
  font-family: ${baseFontFamily};
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  & > hgroup {
    & > h1 {
      font-size: calc(${baseFontSize} * 2.25);
      color: ${textColor};
    }
    & > p {
      font-size: calc(${baseFontSize} * 1.25);
      color: ${lightTextColor};
      margin-top: 8px;
    }
  }
  ${Businesses} > ol > li:hover > & {
    & > hgroup {
      & > h1 {
        color: ${primaryTextColor};
      }
      & > p {
        color: ${baseColor};
      }
    }
  }
`;
